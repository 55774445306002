import React, { Component, Fragment } from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'

class Privacy extends Component {
     render() {
          return (
               <Fragment>
                    <Container>
                         <Row className="p-2">
                              <Col className="shadow-sm bg-white mt-2" md={12} lg={12} sm={12} xs={12}>
                                   <h4 className="section-title-login">Privacy Page </h4>
                                   <p className="section-title-contact">
                                        My name is Mohammad ali khan (Golam Rabby). I am a computer science graduate. I aim to be a  Software engineer. I wish I will serve the nation. pray for me.
                                   </p>


                              </Col>
                         </Row>
                    </Container>
               </Fragment>
          )
     }
}

export default Privacy
